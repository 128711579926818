@import "../../App.scss";

.item-header {
  width: 100%;
  margin: 2px auto; /* Center the item-header */
  padding-bottom: 4px;
  margin-bottom: 10px;
}

.item-header h2 {
  margin: 7px 0;
  font-size: 1.5em;
  color: $primary-dark;
  // color: $light-brown;
  // -webkit-text-stroke: 1px $primary-dark;
}

.item-subheader {
  width: 100%;
  margin-top: -25px;
  color: $dark-brown;
}

.item-details-container {
  display: flex;
  margin: -5px;
  width: 100%;
}

.map-container {
  display: flex; // Make map-container a flex container
  flex-direction: column; // Stack children vertically
  position: relative;
  border: 1px solid #ccc;
  padding: 20px;
  width: 300px; // Fixed width for map-container
}

.map-image-container {
  flex: 1; // Takes 1/3 of the available height
  width: 100%;
  height: auto;
  margin-bottom: 5px; // Add margin to separate from text-container
}

.map-image {
  width: 100%;
  height: auto;
}

.text-container {
  flex: 2; // Takes 2/3 of the available height
  width: 100%;
  margin-left: 10px;
}

.text-pair {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  white-space: nowrap; // Prevent text from wrapping
}

.static-text {
  margin: 3px;
}

.dynamic-text {
  margin: 1px;
  white-space: normal; // Allow text to wrap
  font-size: 1.00em; // Set the font size relative to the parent's font size
  color: $light-brown;
}


.static-text {
  color: $primary-dark;
  font-size: 1.02em;
}

.dynamic-text-link {
  margin: 3px;
  white-space: normal; // Allow text to wrap
  font-size: 1.00em; // Set the font size relative to the parent's font size
  color: $light-brown;
}

