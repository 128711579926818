@import "../../App.scss";

.map-image-container {
  // Add styling for the image container if needed
  max-width: 170px;
  min-width: 50px;
}

.map-image {
  // Add styling for the image here
  max-width: 100%;
  max-height: 200px;
  min-width: 50px;
  border-radius: 5px;
}
