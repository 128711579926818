//Palette
$primary-color: darken(#98c57d, 3%);
$primary-dark: darken(#517442, 10%);
$dark-brown: #5c3b2b;
$medium-brown: lighten($dark-brown, 15%);
$light-brown: #e4dccd;
$medium-grey: #827264;
$light-blue: #5a9cac;
$dark-blue: #2a7d90;
$red-city: #c91c2a;
$blue-city: #009ccc;
$yellow-city: #eaa511;
$purple-city: #895891;
$light-purple: lighten($purple-city, 10%);
$black-city: #1f1b1a;

// Frequent Vars
$link-color: $dark-blue;
$checkbox-color: $light-brown;
$header-color: $light-brown;
$body-color: $light-brown;
$footer-color: $primary-dark;
$light-brown-shadow: rgba($light-brown, 0.25);
$dark-brown-shadow: rgba($dark-brown, 0.25);
$dark-blue-shadow: rgba($dark-blue, 0.25);

// Constants
$CONTAINER_WIDTH : 85%;
$MAX_CONTAINER_WIDTH : 450px;
$MIN_CONTAINER_WIDTH : 200px;

$CONTENT_MARGIN: 90px;


.App {
  text-align: center;
  background-color: $body-color;
  font-family: Verdana, sans-serif;
}

.App-labels {
  text-align: center;
  color: $dark-brown;
}

.App-content {
  margin-top: $CONTENT_MARGIN;
  background-color: $light-brown;
  margin-bottom: 60px;
}

.App-containers {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1em;
  width: $CONTAINER_WIDTH ;
  max-width: $MAX_CONTAINER_WIDTH;
  min-width: $MIN_CONTAINER_WIDTH;
  padding-bottom: 10px;
  padding-top: 10px;
  margin: 10px auto;
}

.App-containers-green {
  @extend .App-containers;
  background-color: $primary-color;
  color: $primary-dark;
  border: solid 3px $primary-dark;
}

.App-containers-blue {
  @extend .App-containers;
  background-color: $light-blue;
  color: $light-brown;
  border: solid 3px darken($light-blue, 20%);
}

.App-containers-brown {
  @extend .App-containers;
  background-color: $medium-brown;
  color: $light-brown;
  border: solid 3px $dark-brown;
}

.App-containers-collection {
  @extend .App-containers;
  background-color: $light-blue;
  color: $light-brown;
  border: solid 3px darken($light-blue, 20%);
  margin-top: $CONTENT_MARGIN;
}

.App-containers-clear {
  @extend .App-containers;
  background-color: rgba($primary-dark, 0.0);
  color: $light-brown;
  margin-bottom: -18px;
}

.App-containers-compact{
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1em;
  width: $CONTAINER_WIDTH ;
  max-width: $MAX_CONTAINER_WIDTH;
  min-width: $MIN_CONTAINER_WIDTH;
  max-height: 30px;
  padding-bottom: 10px;
  padding-top: 10px;
  margin: 10px auto;
  background-color: $primary-color;
  color: $primary-dark;
  border: solid 3px $primary-dark;
}

.App-link {
  color: $blue-city;
}

a {
  color: $link-color;
  white-space: normal; // Allow text to wrap
  font-size: 1.01em; // Set the font size relative to the parent's font size
  &:hover {
    color: darken($link-color, 10%);
  }
  &:visited {
    color: darken($link-color, 20%);
  }
}

.button {
  padding: 10px;
  margin: 15px;
  min-height: 35px;
  max-height: 39px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.0em;
}

.green-button {
  @extend .button;
  background-color: $primary-dark;
  color:$light-brown;
  font-weight: bold;

    &:hover {
      background-color:darken($primary-dark, 10%);
      color: $light-brown;
    }

    &:active {
      background-color: $primary-dark;
      color: $light-brown;
    }
}

.brown-button {
  margin-top: 5px;
  margin-bottom: 15px;
  margin-right: 15px;
  margin-left: 5px;
  min-height: 30px;
  max-height: 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  background-color: $light-brown;
  color:$dark-brown;
  user-select: none;
  box-shadow: 0 4px 6px $dark-brown-shadow;
  transition: box-shadow 0.3s;
  font-weight: bold;
  border: solid 1px $dark-brown;

    &:hover {
      background-color:darken($light-brown, 10%);
      color: $dark-brown;
    }

    &:active {
      background-color: $dark-brown;
      color: $light-brown;
    }
}

.blue-button {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 15px;
  margin-left: 5px;
  min-height: 30px;
  max-height: 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  background-color: $light-brown;
  color:darken($light-blue, 20%);
  user-select: none;
  box-shadow: 0 4px 6px $dark-blue-shadow;
  transition: box-shadow 0.3s;
  font-weight: bold;
  border: solid 2px $light-brown;

    &:hover {
      background-color: darken($light-blue, 20%);
      color: $light-brown;
      border-color: $light-blue;
      border: solid 2px;
    }

    &:active {
      background-color: darken($light-blue, 20%);
      color: $light-blue;
    }
}

.wider-map-container {
  width: 100%;
}