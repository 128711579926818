$logo_size: 65px;

.shopping-link {

  img {
    max-width: $logo_size;
    min-width: $logo_size;
    min-height: $logo_size;
    max-height: $logo_size;
    margin-top: 10px;
    border-radius: 3px;
  }

  h4 {
    margin-top:3px;
    margin-bottom: 3px;
  }
}