@import "../../App.scss";
//Filters.scss

.filters-header {
  margin-top: 5px;
}

.toggle-row {
  display: flex;
  margin-bottom: 8px;
  margin-top: 5px;
  z-index: 1;
  width: 100%;
}

.toggle-left {
  flex: 2;
  display: flex;
  align-items: right;
  justify-content: right;
}
.toggle-right {
  flex: 2;
  display: flex;
  align-items: left;
}

.toggle-middle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-label {
  margin: 0 10px;
  font-weight: bold;
}

.toggle-switch {
  position: relative;
  width: 40px;
  height: 20px;
  background-color: $primary-color;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-switch.active {
  background-color: darken($light-blue, 20%);
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: $light-brown;
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggle-switch.active .slider {
  transform: translateX(20px);
}

.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  padding: 10px;
  margin-bottom: 3px;

  .checkbox-label {
    display: flex;
    flex-direction: column;
    align-items: center;

    .checkbox-input {
      display: none;
    }

    .checkbox-button {
      width: 25px;
      height: 25px;
      border: 1px solid $light-blue;
      background-color: $checkbox-color;
      color: darken($light-blue, 20%);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;
      font-weight: bold;

       &.checked {
        border-color: darken($light-blue, 20%);
        background-color: darken($light-blue, 20%);
        color: $light-brown;
        border: 1px solid;
      }

      &.touched {
        border-color: darken($light-blue, 20%);
        background-color: darken($light-blue, 20%);
        color: $light-brown;
      }

      &.hovered {
        border-color: darken($light-blue, 20%);
        background-color: darken($light-blue, 20%);
        color: $light-brown;
        border: 1px solid;
      }
    }
    }
  }

.search-bar {
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  label {
    margin-right: 10px;
    color:$dark-brown;
  }

  input {
    padding: 5px;
    border: 1px solid $light-blue;
    border-radius: 5px;
    background-color: $light-brown;
    outline: none;

    &:focus {
      border-color: darken($dark-brown, 10%);
      background-color:darken($light-brown, 10%);
    }
  }
}

