@import "../../App.scss";

.search-container-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 14px;
}

.search-bar-user {
  display: flex;
  align-items: center;

  label {
    margin-right: 10px;
    color:$dark-brown;
  }

  input {
    padding: 5px;
    border: 1px solid $light-blue;
    border-radius: 5px;
    background-color: $light-brown;
    outline: none;
    width: 125px;
    height: 20px;

    &:focus {
      border-color: darken($dark-brown, 10%);
      background-color:darken($light-brown, 10%);
    }
  }
}

.message {
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
}

.error-message {
  color: $red-city;
  font-size: 20px;
  font-weight: bold;
}

.success-message {
  color: $light-brown;
  font-size: 20px;
  font-weight: bold;
}

.loading-message {
  color: $light-brown;
  font-size: 20px;
  font-weight: bold;
}
