@import "../../App.scss";

.App-footer {
  background-color: $checkbox-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: $light-brown;
  border-top: solid 3px $dark-brown;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  margin-top: 10px;
  min-height: 45px;
  z-index: 99;
}


.buyButton {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  color: $light-brown;
  background-color: $light-blue;
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 0.5rem;
  font-size: 1rem;
  letter-spacing: 0.6px;
  box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5);
}
.buyButton:hover,
.buyButton:active,
.buyButton:focus {
  text-decoration: underline;
  background-color: $dark-blue;
  box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5);
  opacity: 0.85;
  color: #ffffff;
}

.coffeeImage {
  height: 2vh;
  box-shadow: none;
  border: none;
  vertical-align: middle;
}

.coffeeButtonText {
  margin-left: 15px;
  font-size: 0.8rem;
  vertical-align: middle;
}