@import "../../App.scss";

.App-containers-clear {
  clear: both;
  width: 100%;
}

.random-map-text {
  font-size: .9em;
  font-weight: bold;
  color: $dark-brown;
  align-content: center;
  margin-top: -3px;
  margin-right: 8px;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.image-container img {
  width: 75px;
  height: 50px;
  margin-left: 0;
}

.toggle-row-view {
  display: flex;
  flex: 1;
  margin-bottom: 8px;
  margin-top: 5px;
  z-index: 1;
  width: 100%;
  align-items: center;  // Align items to center vertically
  align-content: center;
}

.toggle-left-view,
.toggle-middle-view {
  flex: 0.2;  // Adjust the flex value to make it take up less space
  display: flex;
  align-items: center;
  padding-right: 10px;  // Adjust the padding as needed
}

.toggle-right-view {
  flex: 0;  // Adjust the flex value to make it take up more space
  display: flex;
  align-items: flex-end;  // Align items to flex-end (bottom)
}

.toggle-label-view {
  font-weight: bold;
  font-size: .9em;
  color: $dark-brown;
  margin-right: 5px;  // Adjust the margin as needed
}

.toggle-switch-view {
  position: relative;
  width: 40px;
  height: 20px;
  background-color: lighten($medium-grey, 20%);
  border-radius: 15px;
  border: 1px solid $dark-blue;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-switch-view.active {
  background-color: darken($light-blue, 20%);
}

.slider-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: $light-brown;
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggle-switch-view.active .slider-view {
  transform: translateX(20px);
}


