@import "../../App.scss";

.random-item-header {
  text-align: center;
  margin: 0 auto; /* Center the item-header */
  color: $dark-brown;
  margin-bottom: -10px;
  margin-top: -10px;
}

.modal-overlay {
  position: fixed;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: $CONTAINER_WIDTH + 2%;
  max-width: $MAX_CONTAINER_WIDTH - 30px;
  min-width: $MIN_CONTAINER_WIDTH - 30px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $light-brown;
  border-radius: 8px;
  border: 3px solid $primary-dark;
  z-index: 999;
}

.modal-content {
  width: 98%;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $light-brown;
  color: $primary-color;
}

.App-containers-random {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1em;
  width: 100%;
  max-width: 250px;
  min-width: 250px;
  min-height: 275px;
  padding-bottom: 10px;
  padding-top: 10px;
  margin: 10px auto;
  background-color: $primary-color;
  color: $primary-dark;
  border: solid 3px $primary-dark;
  text-align: center;
}

.modal-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
