@import "../../App.scss";

.item-header-compact {
  width: 100%;
}

.item-header-compact h2 {
  font-size: .9em;
  color: $primary-dark;
  text-align: left;
  margin: 0;
}

.text-container-compact {
  flex: 2;
  width: 100%;
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; /* Align items to the right */
}

.item-header-compact {
  max-width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: -13px;
}

.player-count-text-row {
  display: flex;
  flex-direction: row;
  text-align: right;
  margin-left: 10px;
  margin-bottom: 15px;
  padding-right: 15px;
}

.player-count-text {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  color: $dark-blue;
}

.big-text {
  color: $light-brown;
  font-size: 1.4em;
}

.small-text {
  color: $dark-blue;
  font-size: 1.1em;
}
