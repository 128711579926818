@import "../../App.scss";

.header {
  background-color: $header-color;
  display: flex;
  align-items: center;
  justify-content: center; /* Add this line to distribute items along the flex container */
  font-size: calc(10px + 1vmin);
  color: $dark-brown;
  border-bottom: solid 3px $dark-brown;
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px; /* Adjust the height as needed */
  margin-bottom: 10px;
  z-index: 100;

  img {
    max-width: 400px;
    max-height: 150px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h1 {
    margin-left: 10px;
  }
}

